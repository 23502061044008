import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import axios from 'axios';
import api from '../api';
import { toast } from 'react-toastify';
import store from "../redux/store";


class DefaultFooter extends Component {

  constructor(props) {
    super(props);

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: '',
    }
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

 async onSubmit(e) {
    e.preventDefault();

    const data = {
      email_address: this.state.email,
      status: 'subscribed'
    }

  api.post(`/newsletter-subscribe`, data)
    .then(res => {
      if (res.data.status) {
        toast.success('Thank you for subscribing!');
        this.setState({
          email: ""
        })
      } else {
        toast.error('An error occurred! The email might have been subscribed before or is not entered correctly');
      }
    }).catch(error => {
      let err = store.getState().error;
      if (err) {
        toast.error(err.message);
        this.props.history.push(err.redirectUrl)
      }
      console.log(error)
    });
}

render() {
  return (
    <div className='footer-div'>
      <footer className="space-pt footer bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 order-xl-1 order-lg-1  order-md-1 col-lg-6 col-md-6">
              <div className="footer-contact-address">
                {/* <h6 className="text-white mb-4">Where To Find Us </h6> */}
                <ul className="list-unstyled">
                  <li>
                    <i className="fas fa-map-signs mt-2"></i>
                    <div className="address-info">
                      <h6>Penthouse & 2nd Floor,</h6>
                      <p>20, Campbell Street,<br />
                        Lagos Island, Lagos, Nigeria.<br />
                        P.O. Box 75649,<br />
                        Victoria Island.</p>
                    </div>
                  </li>
                  <li>
                    <i className="far fa-envelope mt-2"></i>
                    <div className="address-info">
                      <h6><a href="mailto:info@dynamicportfolio.com">
                        info@dynamicportfolio.com</a></h6>
                      <h6><a href="mailto:invest@dynamicportfolio.com">
                        invest@dynamicportfolio.com</a></h6>
                      <p>Mon-Fri 8:00am-6:00pm </p>
                    </div>
                  </li>
                  <li>
                    <i className="fas fa-headphones-alt mt-2"></i>
                    <div className="address-info">
                      <h6><a href="tel:2348055550606">+(234) 0805 555 0606</a></h6>
                      <p>Online support </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 order-xl-2 order-lg-3 order-md-3 col-lg-12 col-md-12  mt-4">
              <h4 className="text-white mb-2">Newsletter
              </h4>
              <p className="lead  mb-4 mb-md-3">Sign up to our Newsletter to get the latest news and offers.</p>
              <div className="row">
                <div className="col-sm-8 col-xl-11">
                  <form className="form-inline input-with-btn" onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <input type="email" className="form-control" value={this.state.email} onChange={this.onChangeEmail} placeholder="Enter your email" />
                    </div>
                    <button type="submit" className="btn btn-primary">Subscribe</button>
                  </form>
                </div>
              </div>
              <div className="follow-us mt-4">
                <h6 className="text-white mb-2">Follow Us </h6>
                <ul className="list-unstyled">
                  <li><a href={'https://www.facebook.com/DynamicPortfolioLimited'} target={'_blank'}> <i className="fab fa-facebook-f"></i> </a></li>
                  <li><a href={'https://www.twitter.com/DynamicpLimited'} target={'_blank'}> <i className="fab fa-twitter"></i> </a></li>
                  <li><a href={'https://www.instagram.com/DynamicPortfolioLtd'} target={'_blank'}> <i className="fab fa-instagram"></i> </a></li>
                  <li><a href={'https://www.linkedin.com/company/dynamic-portfolio-limited'} target={'_blank'}> <i className="fab fa-linkedin-in"></i> </a></li>
                  <li><a href={'https://api.whatsapp.com/send/?phone=%2B2348055550606&text=Hello&app_absent=0'} target={'_blank'}> <i className="fab fa-whatsapp"></i> </a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 order-xl-3 order-lg-2 order-md-2 col-lg-6 col-md-6 mt-4">
              <div className="footer-link">
                <div className="row">
                  <div className="col-sm-6">
                    <h6 className="text-white">Discover</h6>
                    <ul className="list-unstyled">
                      <li><Link to={"/about"}><span>About us</span></Link></li>
                      <li><Link to={"/contact"}><span>Contact us</span></Link></li>
                      <li><Link to={"/faqs"}><span>FAQ</span></Link></li>
                      <li><Link to={"/careers"}><span>Careers</span></Link></li>
                      <li><a target={"_blank"} href="https://www.dynamicportfolioonline.com/IW/IWLogin.aspx"><span>Account Login</span></a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <h6 className="text-white">Services</h6>
                    <ul className="list-unstyled">
                      <li><Link to={"/services/stockbroking"}><span>Stockbroking</span></Link></li>
                      <li><Link to={"/services/issuing-house"}><span>Issuing House</span></Link></li>
                      <li><Link to={"/services/financial-advisory"}><span>Financial Advisory</span></Link></li>
                      <li><Link to={"/services/portfolio-management"}><span>Portfolio Management</span></Link></li>
                      <li><Link to={"/services/fund-management"}><span>Fund Management</span></Link></li>
                    </ul>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <h6 className="text-white">Featured Links</h6>
                    <ul className="list-unstyled">
                      <li title='Securities and Exchange Commission, Nigeria'><a target={"_blank"} href="https://sec.gov.ng"><span>SEC Nigeria</span></a></li>
                      <li title='Nigerian Exchange Group'><a target={"_blank"} href="https://ngxgroup.com"><span>NGX Group</span></a></li>
                      <li  title='FMDQ Group PLC'><a target={"_blank"} href="https://fmdqgroup.com"><span>FMDQ Group</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-4">
          <div className="container">
            <div className="row">
              <div className="copyright text-center d-block">
                <span className="mb-0 text-white"> &copy; Copyright <span id="copyright">{new Date().getFullYear()}</span> <Link to={"/"}> Dynamic Portfolio </Link> All Rights Reserved </span>
                <span>|
                 Designed and developed by <a target={"_blank"} href="https://setsoft.tech"> Setsoft</a></span></div>
            </div>
          </div>
        </div>
      </footer>
      <div id="back-to-top" className="back-to-top">
        <Link to={"#"}><i className="fas fa-angle-up"></i> </Link>
      </div>
    </div>
  );
}
}

export default DefaultFooter;