import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import api from '../api';
import { Link } from 'react-router-dom';

const select = appState => {
  return {
    user: {
      isUserAuthenticated: appState.isUserAuthenticated,
      id: appState.isUserAuthenticated ? appState.user.id : 0,
      fullname: appState.isUserAuthenticated ? `${appState.user.first_name} ${appState.user.last_name}` : "",
      initials: appState.isUserAuthenticated ? `${appState.user.first_name.charAt(0)}${appState.user.last_name.charAt(0)}` : "",
      permissions: appState.isUserAuthenticated ? appState.user.designation.permissions : false
    },
    error: appState.error
  }
}

const Wrapper = connect(select);

const Notification = props => (
  <div>
    <div className="dropdown-divider"></div>
    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" style={{ whiteSpace: "normal" }} href={props.notification.link}>
      <div className="preview-thumbnail">
        <i className="mdi mdi-alert m-auto text-primary"></i>
      </div>
      <div className="preview-item-content py-2">
        <h6 style={{ fontSize: "0.85rem", lineHeight: "20px" }} className="preview-subject font-weight-normal text-dark mb-1">{props.notification.message}</h6>
        <p style={{ marginTop: "10px" }} className="font-weight-light small-text mb-4"> {props.notification.time} </p>
      </div>
    </Dropdown.Item>
  </div>
)

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.fetchNotifications = this.fetchNotifications.bind(this);

    this.state = {
      notifications: [],
      count: 0,
      notify: []
    }
  }

  componentDidMount() {
    // if (this.props.user.isUserAuthenticated) this.fetchNotifications();
  }

  fetchNotifications() {
    api.get(`/notifications/five?userId=${this.props.user.id}`, { isNotification: true })
      .then(response => {
        this.setState({
          notifications: response.data.notifications,
          count: response.data.count
        });
      }).then(() => {
        let notify = [];
        this.state.notifications.forEach(notification => {
          let message = ""; let link = ""; let time = "";
          var now = new Date();
          var then = new Date(notification.createdDate);

          var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"));
          var d = moment.duration(ms - (60 * 60 * 1000));

          if (d.years()) {
            time = `${d.years()} ${d.years() > 1 ? `years` : `year`} ago`;
          } else if (d.months()) {
            time = `${d.months()} ${d.months() > 1 ? `months` : `month`} ago`;
          } else if (d.days()) {
            time = `${d.days()} ${d.days() > 1 ? `days` : `day`} ago`;
          } else if (d.hours()) {
            time = `${d.hours()} ${d.hours() > 1 ? `hours` : `hour`} ago`;
          } else if (d.minutes()) {
            time = `${d.minutes()} ${d.minutes() > 1 ? `minutes` : `minute`} ago`;
          } else if (d.seconds()) {
            time = `${d.seconds()} ${d.seconds() > 1 ? `seconds` : `second`} ago`;
          } else {
            time = "Just now";
          }

          if (notification.type === "new-memo") {
            message = `You have a memo from ${notification.memo.fromUser.first_name} ${notification.memo.fromUser.last_name} waiting for your approval`;
            link = `/memos/${notification.memoId}/approvals/${notification.memoApprovalId}?sender=${notification.memo.fromUserId}&designationId=${notification.memoApproval.designationId}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
          else if (notification.type === "memo-return") {
            message = `You have an approval by ${notification.memoApproval.designation.name} for your memo with subject ${notification.memo.subject}`;
            link = `/memoapprovals/${notification.memoId}/${notification.memoApprovalId}?designationId=${notification.memoApproval.designationId}&notification=${notification.id}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
          else if (notification.type === "memo-approved") {
            message = `Your memo with subject ${notification.memo.subject} has been approved`;
            link = `/memos/${notification.memoId}?sender=${notification.memo.fromUserId}&notification=${notification.id}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
          else if (notification.type === "memo-rejected") {
            message = `Your memo with subject ${notification.memo.subject} was rejected`;
            link = `/memos/${notification.memoId}?sender=${notification.memo.fromUserId}&notification=${notification.id}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
          else if (notification.type === "memo-pending") {
            message = `Your memo with subject ${notification.memo.subject} needs your attention`;
            link = `/memos/edit/${notification.memoId}?notification=${notification.id}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
          else if (notification.type === "new-proposal") {
            message = `You have a proposal from ${notification.proposal.organization} waiting for your attention`;
            link = `/proposals/${notification.proposalId}/approvals/${notification.proposalApprovalId}?designationId=${notification.proposalApproval.designationId}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
          else if (notification.type === "new-contractDocument") {
            message = `You have a contract document from ${notification.contractDocument.organization} waiting for your attention`;
            link = `/contractdocuments/${notification.contractDocumentId}/approvals/${notification.contractDocumentApprovalId}?designationId=${notification.contractDocumentApproval.designationId}`;
            notify.push({ "id": notification.id, "message": message, "link": link, "time": time });
          }
        });
        this.setState({
          notify: notify
        })
      }).catch(error => {
        console.log(error)
      });
    setTimeout(this.fetchNotifications, 7500)
  }

  notificationList() {
    return this.state.notify.map((notification, i) => {
      return <Notification id={i + 1} notification={notification} key={notification.id} />
    })
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  render() {
    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.querySelector('.container-scroller').classList.toggle('sidebar-icon-only')}>
            <i className="mdi mdi-menu"></i>
          </button>
          <ul className="navbar-nav navbar-nav-left header-links">
            <Link className="navbar-brand brand-logo-mini align-self-center" to="/">
              {/* <img style={{ height: '25px', width: '25px' }} src={this.props.user.companyLogo ? `${process.env.REACT_APP_FILE_URL}/CompanyLogos/${this.props.user.companyLogo}` : require("../../dassets/images/logo.jpg")} alt="logo" /> */}
              </Link>
            <li className="nav-item d-none d-md-flex">
              <span style={{ fontSize: '13px' }}>Dynamic Portfolio
              </span>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            {/* <li className="nav-item  nav-profile border-0 pl-4">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count bg-success">{this.state.count}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="/notifications">
                    <p className="mb-0 font-weight-medium float-left">You have {this.state.count ? this.state.count : 'no'} new notifications </p>
                    {this.state.count > 5 ?
                      <span className="badge badge-pill badge-primary float-right" to="/notifications">View all</span>
                      : ''}
                  </Dropdown.Item>
                  {this.notificationList()}
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            <li className="nav-item  nav-profile border-0">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span style={{ display: "inline-block" }} className="profile-text">{this.props.user.fullname}</span>
                  <span style={{ display: "inline-block", height: "40px", width: '40px', borderRadius: '40px', backgroundColor: '#fff', color: "#772038", verticalAlign: "center", paddingTop: "12px", fontSize: "18px", fontWeight: "bold" }}>{this.props.user.initials}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  {/* <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" href="!#" onClick={evt => evt.preventDefault()}>
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item href="/user" className="dropdown-item preview-item d-flex align-items-center border-0 mt-2">
                    Manage Accounts
                  </Dropdown.Item>
                  <Dropdown.Item href="/change-password" className="dropdown-item preview-item d-flex align-items-center border-0">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item href="/logout" className="dropdown-item preview-item d-flex align-items-center border-0">Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav >
    );
  }
}

export default Wrapper(Navbar);
