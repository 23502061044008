import * as actions from "./actionTypes";

export const userAuthenticated = user => ({
    type: actions.USER_AUTHENTICATED,
    payload: user
});

export const userUnauthenticated = user => ({
    type: actions.USER_UNAUTHENTICATED,
    payload: user
});

export const userTokenRefreshed = token => ({
    type: actions.USER_TOKEN_REFRESHED,
    payload: token
});

export const userUpdated = user => ({
    type: actions.USER_UPDATED,
    payload: user
});

export const companyUpdated = company => ({
    type: actions.COMPANY_UPDATED,
    payload: company
});

export const companyLogoUpdated = logo => ({
    type: actions.COMPANY_LOGO_UPDATED,
    payload: logo
});

export const isError = error => ({
    type: actions.IS_ERROR,
    payload: error
});

export const isRedirect = url => ({
    type: actions.IS_REDIRECT,
    payload: url
});