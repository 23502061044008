import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from "react-redux";
import store from "./redux/store";

export const PrivateRoute = ({ component: Component, permission: Permission, layout: Layout, ...rest }) => {
    const auth = useSelector(state => state.auth);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    useEffect(() => {
        let auth = store.getState().isUserAuthenticated;
        if (auth) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [auth])

    if (isAuthenticated === null) {
        return <></>
    }

    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated) {
                return (
                    <Redirect to='/login' />
                )
            }
            else if (Permission) {
                if (store.getState().user.designation.permissions[Permission.key] && store.getState().user.designation.permissions[Permission.key][Permission.value]) {
                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )
                } else {
                    return (
                        <Redirect to='/dashboard' />
                    )
                }
            }
            else {
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )
            }
        }
        }
        />
    );
};

export const UnAuthRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const auth = useSelector(state => state.auth)
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    useEffect(() => {
        let auth = store.getState().isUserAuthenticated;
        if (auth) {
            setIsAuthenticated(true)
        } else {
            setIsAuthenticated(false)
        }
    }, [auth])

    if (isAuthenticated === null) {
        return <></>
    }

    return (
        <Route {...rest} render={props =>
            isAuthenticated ? (
                <Redirect to='/dashboard' />
            ) : (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }
        />
    );
};

export const LRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return (
        <Route {...rest} render={props =>
                <Layout>
                    <Component {...props} />
                </Layout>
        }
        />
    );
};