import axios from 'axios';
import store from "./redux/store";
import NProgress from 'nprogress';
import jwt from 'jsonwebtoken';
import { isError, userUnauthenticated, userTokenRefreshed } from "./redux/actions";
import Swal from 'sweetalert2';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`,
});

const isTokenExpired = (token) => {
    var decodedToken = jwt.decode(token, { complete: true });
    var dateNow = (new Date()).getTime() / 1000;
    var isExpired = decodedToken.payload.exp < dateNow ? true : false;
    return isExpired;
}

const isRefreshSuccess = (token) => tryRefreshingTokens(token);

const tryRefreshingTokens = async (token) => {
    // Try refreshing tokens using refresh token
    let refreshToken = store.getState().token.refreshToken;
    const credentials = { accessToken: token, refreshToken: refreshToken };
    let isRefreshSuccess = false;
    await axios.post(`${process.env.REACT_APP_API_URL}/token/refresh`, credentials)
        .then((res) => {
            // If token refresh is successful, set new tokens in store.
            store.dispatch(userTokenRefreshed(res.data));
            isRefreshSuccess = true;
        }).catch((err) => {
            isRefreshSuccess = false;
        });
    return isRefreshSuccess;
}


instance.interceptors.request.use(config => {
    if (!config.isNotification)
        // NProgress.start();
        Swal.showLoading()
    if (!config.noAuth) {
        // let token = store.getState().token.accessToken;
        let token = store.getState().token;
        config.headers.Authorization = token ? `Bearer ${token}` : null;
        // if (isTokenExpired(token)) {
        //     if (!isRefreshSuccess(token)) {
        //         store.dispatch(userUnauthenticated({}));
        //         return null
        //     }
        // }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    // NProgress.done();
    Swal.close()
    if (response.status === 200 || response.status === 201 || response.status === 204)
        return response;
}, error => {
    // NProgress.done();
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred!',
        confirmButtonColor: "#702138e6"
    })
    if (!error.response) {
        store.dispatch(isError({ "status": 500, "message": "Network Error", "redirectUrl": "#" }));
    }
    else if (error.response.status === 401) {
        store.dispatch(isError({ "status": error.response.status, "message": "Invalid username or password", "redirectUrl": "/logout" }));
    }
    else if (error.response.status === 400) {
        store.dispatch(isError({ "status": error.response.status, "message": "Bad Request", "redirectUrl": "#" }));
    }
    else if (error.response.status === 403) {
        store.dispatch(isError({ "status": error.response.status, "message": error.response.data.errorMessage, "redirectUrl": "#" }));
    }
    else if (error.response.status === 404) {
        store.dispatch(isError({ "status": error.response.status, "message": "Not found", "redirectUrl": "#" }));
    }
    else if (error.response.status === 409) {
        store.dispatch(isError({ "status": error.response.status, "message": error.response.data.error, "redirectUrl": "/logout" }));
    }
    else if (error.response.status === 500) {
        store.dispatch(isError({ "status": error.response.status, "message": error.response.data.errorMessage, "redirectUrl": "#" }));
    }
    else {
        store.dispatch(isError({ "status": error.response.status, "message": error.response.data.errorMessage, "redirectUrl": "#" }));
    }
    return Promise.reject(error);
});

export default instance