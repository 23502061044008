import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import 'react-toastify/dist/ReactToastify.css';
import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from "./redux/store";
import { ToastContainer } from 'react-toastify';

class App extends Component {
  // state = {}
  componentDidMount() {
    this.onRouteChanged();
  }
  render() {
    return (
      <Provider store={store}>
        <AppRoutes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </Provider >
    );
  }

  componentDidUpdate(prevProps) {
    this.onRouteChanged();
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const elem = document.querySelector('.dpf .header .navbar-collapse');
    if (elem !== null && elem.classList.contains('show')) {
      elem.classList.remove('show')
    }
  }

}

export default withRouter(App);
