import * as actions from "./actionTypes";
import produce from "immer"

let appState = {
    "isUserAuthenticated": false,
    "token": null,
    "user": {},
    "error": {},
    "intendedUrl": null
};

const reducer = (state = appState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.USER_AUTHENTICATED:
                draft.isUserAuthenticated = true;
                draft.token = action.payload.token;
                draft.user = action.payload.user;
                break;
            case actions.USER_UNAUTHENTICATED:
                draft.isUserAuthenticated = false;
                draft.token = null;
                draft.user = action.payload;
                break;
            case actions.USER_TOKEN_REFRESHED:
                draft.token = action.payload;
                break;
            case actions.USER_UPDATED:
                draft.user.first_name = action.payload.first_name;
                draft.user.last_name = action.payload.last_name;
                draft.user.full_name = action.payload.full_name;
                draft.user.email = action.payload.email;
                break;
            case actions.COMPANY_UPDATED:
                draft.user.company.name = action.payload.name;
                draft.user.company.address = action.payload.address;
                draft.user.company.website = action.payload.website;
                draft.user.company.phone = action.payload.phone;
                break;
            case actions.COMPANY_LOGO_UPDATED:
                draft.user.company.logo = action.payload;
                break;
            case actions.IS_ERROR:
                draft.error = action.payload;
                break;
            case actions.IS_REDIRECT:
                draft.intendedUrl = action.payload;
                break;
            default:
                return state;
        }
    });

export default reducer