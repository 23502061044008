
import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute, UnAuthRoute, LRoute } from './RouteMiddleware';

import Navbar from './shared/Navbar';
import DefaultNavbar from './shared/default-navbar';
import DefaultFooter from './shared/default-footer';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';

import Spinner from '../app/shared/Spinner';
// import { render } from 'nprogress';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Error404 = lazy(() => import('./user-pages/Error404'));
const Error500 = lazy(() => import('./user-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const ForgotPassword = lazy(() => import('./user-pages/forgot-password'));
const ResetPassword = lazy(() => import('./user-pages/reset-password'));
const Logout = lazy(() => import('./user-pages/Logout'));

// departments
const ListDepartments = lazy(() => import('./departments/list'));
const CreateDepartment = lazy(() => import('./departments/create'));
const EditDepartment = lazy(() => import('./departments/edit'));
const DepartmentDetails = lazy(() => import('./departments/details'));

// jobs
const ListJobs = lazy(() => import('./jobs/list'));
const CreateJob = lazy(() => import('./jobs/create'));
const EditJob = lazy(() => import('./jobs/edit'));
const JobDetails = lazy(() => import('./jobs/details'));

// ngx
const ListNgx = lazy(() => import('./ngxindex/list'));
const CreateNgx = lazy(() => import('./ngxindex/create'));
const EditNgx = lazy(() => import('./ngxindex/edit'));
const NgxDetails = lazy(() => import('./ngxindex/details'));

// documents
const ListDocuments = lazy(() => import('./documents/list'));
const CreateDocuments = lazy(() => import('./documents/create'));
const EditDocument = lazy(() => import('./documents/edit'));
const DocumentDetails = lazy(() => import('./documents/details'));

// designations
const ListDesignations = lazy(() => import('./designations/list'));
const CreateDesignation = lazy(() => import('./designations/create'));
const EditDesignation = lazy(() => import('./designations/edit'));
const DesignationDetails = lazy(() => import('./designations/details'));

// contents
const ListContent = lazy(() => import('./contents/list'));
const CreateContent = lazy(() => import('./contents/create'));
const EditContent = lazy(() => import('./contents/edit'));
const ContentDetails = lazy(() => import('./contents/details'));

// Users
const ListUsers = lazy(() => import('./users/list'));
const CreateUser = lazy(() => import('./users/create'));
const EditUser = lazy(() => import('./users/edit'));
const UserDetails = lazy(() => import('./users/details'));
const EditUserSelf = lazy(() => import('./users/user-edit'));
const UserDetailsSelf = lazy(() => import('./users/user-details'));
const ChangePassword = lazy(() => import('./users/change-password'));

// EForms
const ListEForms = lazy(() => import('./eforms/list'));
const CreateEForm = lazy(() => import('./eforms/create'));
const EFormDetails = lazy(() => import('./eforms/details'));

// IForms
const ListIForms = lazy(() => import('./iforms/list'));
const CreateIForm = lazy(() => import('./iforms/create'));
const IFormDetails = lazy(() => import('./iforms/details'));

// KYCIForms
const KYCListIForms = lazy(() => import('./iformsKYC/list'));
const KYCCreateIForm = lazy(() => import('./iformsKYC/create'));
const KYCIFormDetails = lazy(() => import('./iformsKYC/details'));

// JForms
const ListJForms = lazy(() => import('./jforms/list'));
const CreateJForm = lazy(() => import('./jforms/create'));
const JFormDetails = lazy(() => import('./jforms/details'));

// CForms
const ListCForms = lazy(() => import('./cforms/list'));
const CreateCForm = lazy(() => import('./cforms/create'));
const CFormDetails = lazy(() => import('./cforms/details'));

// KYCCForms
const KYCListCForms = lazy(() => import('./cformsKYC/list'));
const KYCCreateCForm = lazy(() => import('./cformsKYC/create'));
const KYCCFormDetails = lazy(() => import('./cformsKYC/details'));

// Reports
const PendingDocument = lazy(() => import('./reports/pending-documents-list'));
const Notifications = lazy(() => import('./reports/notifications'));
const Search = lazy(() => import('./reports/search'));

const BlankPage = lazy(() => import('./user-pages/BlankPage'));

const Home = lazy(() => import('./pages/home'));
const About = lazy(() => import('./pages/about'));
const Experience = lazy(() => import('./pages/experience'));
const Contact = lazy(() => import('./pages/contact'));
const BoardOfDirectors = lazy(() => import('./pages/board-of-directors'));
const ManagementTeam = lazy(() => import('./pages/management-team'));
const Faqs = lazy(() => import('./pages/faqs'));
const Stockbroking = lazy(() => import('./pages/services/stockbroking'));
const IssuingHouse = lazy(() => import('./pages/services/issuing-house'));
const FinancialAdvisory = lazy(() => import('./pages/services/financial-advisory'));
const FundManagement = lazy(() => import('./pages/services/fund-management'));
const PortfolioManagement = lazy(() => import('./pages/services/portfolio-management'));
const OtherProducts = lazy(() => import('./pages/services/other-products'));
const Research = lazy(() => import('./pages/research'));
const PriceList = lazy(() => import('./pages/pricelist'));
const NGXIndex = lazy(() => import('./pages/ngx_index'));
const Careers = lazy(() => import('./pages/careers'));


const DefaultLayout = (props) => {
  require('./DApp.scss');
  require('./App.css');
  return (
    <div className="dpf">
      <DefaultNavbar />
      <div className='dpfbody'>
        {props.children}
      </div>
      <DefaultFooter />
    </div>
  )
}


const AdminLayout = (props) => {
  require('./App.scss');
  require('./App.css');
  return (
    <div className='admin'>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper admin">
              {props.children}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}


const UnAuthLayout = (props) => {
  require('./App.scss');
  require('./App.css');
  return (
    <div className='admin'>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


class AppRoutes extends Component {

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>

          <PrivateRoute layout={UnAuthLayout} path="/logout" component={Logout} />

          <PrivateRoute exact layout={AdminLayout} path="/dashboard" component={Dashboard} />

          {/* Documents */}
          <PrivateRoute exact layout={AdminLayout} path="/documents" component={ListDocuments} />
          <PrivateRoute exact layout={AdminLayout} path="/documents/create" component={CreateDocuments} />
          <PrivateRoute exact layout={AdminLayout} path="/documents/edit/:id" component={EditDocument} />
          <PrivateRoute exact layout={AdminLayout} path="/documents/:id" component={DocumentDetails} />

          {/* Departments */}
          <PrivateRoute permission={{ key: "department", value: "read" }} exact layout={AdminLayout} path="/departments" component={ListDepartments} />
          <PrivateRoute permission={{ key: "department", value: "create" }} exact layout={AdminLayout} path="/departments/create" component={CreateDepartment} />
          <PrivateRoute permission={{ key: "department", value: "update" }} exact layout={AdminLayout} path="/departments/edit/:id" component={EditDepartment} />
          <PrivateRoute permission={{ key: "department", value: "read" }} exact layout={AdminLayout} path="/departments/:id" component={DepartmentDetails} />

          {/* Jobs */}
          <PrivateRoute exact layout={AdminLayout} path="/jobs" component={ListJobs} />
          <PrivateRoute exact layout={AdminLayout} path="/jobs/create" component={CreateJob} />
          <PrivateRoute exact layout={AdminLayout} path="/jobs/edit/:id" component={EditJob} />
          <PrivateRoute exact layout={AdminLayout} path="/jobs/:id" component={JobDetails} />

          {/* Jobs */}
          <PrivateRoute exact layout={AdminLayout} path="/ngxindex" component={ListNgx} />
          <PrivateRoute exact layout={AdminLayout} path="/ngxindex/create" component={CreateNgx} />
          <PrivateRoute exact layout={AdminLayout} path="/ngxindex/edit/:id" component={EditNgx} />
          <PrivateRoute exact layout={AdminLayout} path="/ngxindex/:id" component={NgxDetails} />

          {/* Jobs */}
          <PrivateRoute exact layout={AdminLayout} path="/contents" component={ListContent} />
          <PrivateRoute exact layout={AdminLayout} path="/contents/create" component={CreateContent} />
          <PrivateRoute exact layout={AdminLayout} path="/contents/edit/:id" component={EditContent} />
          <PrivateRoute exact layout={AdminLayout} path="/contents/:id" component={ContentDetails} />

          {/* Designations */}
          <PrivateRoute permission={{ key: "designation", value: "read" }} exact layout={AdminLayout} path="/designations" component={ListDesignations} />
          <PrivateRoute permission={{ key: "designation", value: "create" }} exact layout={AdminLayout} path="/designations/create" component={CreateDesignation} />
          <PrivateRoute permission={{ key: "designation", value: "update" }} exact layout={AdminLayout} path="/designations/edit/:id" component={EditDesignation} />
          <PrivateRoute permission={{ key: "designation", value: "read" }} exact layout={AdminLayout} path="/designations/:id" component={DesignationDetails} />

          {/* Users */}
          <PrivateRoute permission={{ key: "user", value: "read" }} exact layout={AdminLayout} path="/users" component={ListUsers} />
          <PrivateRoute permission={{ key: "user", value: "create" }} exact layout={AdminLayout} path="/users/create" component={CreateUser} />
          <PrivateRoute permission={{ key: "user", value: "update" }} exact layout={AdminLayout} path="/users/edit/:id" component={EditUser} />
          <PrivateRoute permission={{ key: "user", value: "read" }} exact layout={AdminLayout} path="/users/:id" component={UserDetails} />
          <PrivateRoute exact layout={AdminLayout} path="/user/edit" component={EditUserSelf} />
          <PrivateRoute exact layout={AdminLayout} path="/user" component={UserDetailsSelf} />
          <PrivateRoute exact layout={AdminLayout} path="/change-password" component={ChangePassword} />

          {/* EForms */}
          <PrivateRoute permission={{ key: "iform", value: "read" }} exact layout={AdminLayout} path="/estate-forms" component={ListEForms} />
          <LRoute exact layout={DefaultLayout} path="/estate-forms/create" component={CreateEForm} />
          <PrivateRoute exact layout={AdminLayout} path="/estate-forms/view" component={EFormDetails} />

          {/* IForms */}
          <PrivateRoute permission={{ key: "iform", value: "read" }} exact layout={AdminLayout} path="/individual-forms" component={ListIForms} />
          <LRoute exact layout={DefaultLayout} path="/individual-forms/create" component={CreateIForm} />
          <PrivateRoute exact layout={AdminLayout} path="/individual-forms/view" component={IFormDetails} />

          {/* KYCIForms */}
          <PrivateRoute permission={{ key: "iform", value: "read" }} exact layout={AdminLayout} path="/individual-kyc-forms" component={KYCListIForms} />
          <LRoute exact layout={DefaultLayout} path="/individual-kyc-forms/create" component={KYCCreateIForm} />
          <PrivateRoute exact layout={AdminLayout} path="/individual-kyc-forms/view" component={KYCIFormDetails} />

          {/* JForms */}
          <PrivateRoute permission={{ key: "jform", value: "read" }} exact layout={AdminLayout} path="/joint-forms" component={ListJForms} />
          <LRoute exact layout={DefaultLayout} path="/joint-forms/create" component={CreateJForm} />
          <PrivateRoute exact layout={AdminLayout} path="/joint-forms/view" component={JFormDetails} />

          {/* CForms */}
          <PrivateRoute permission={{ key: "cform", value: "read" }} exact layout={AdminLayout} path="/corporate-forms" component={ListCForms} />
          <LRoute exact layout={DefaultLayout} path="/corporate-forms/create" component={CreateCForm} />
          <PrivateRoute exact layout={AdminLayout} path="/corporate-forms/view" component={CFormDetails} />

          {/* KYCCForms */}
          <PrivateRoute permission={{ key: "cform", value: "read" }} exact layout={AdminLayout} path="/corporate-kyc-forms" component={KYCListCForms} />
          <LRoute exact layout={DefaultLayout} path="/corporate-kyc-forms/create" component={KYCCreateCForm} />
          <PrivateRoute exact layout={AdminLayout} path="/corporate-kyc-forms/view" component={KYCCFormDetails} />

          {/* Reports */}
          <PrivateRoute exact layout={AdminLayout} path="/pending-documents" component={PendingDocument} />
          <PrivateRoute exact layout={AdminLayout} path="/notifications" component={Notifications} />
          <PrivateRoute permission={{ key: "report", value: "read" }} exact layout={AdminLayout} path="/search" component={Search} />


          <UnAuthRoute path="/login" layout={UnAuthLayout} component={Login} />
          <UnAuthRoute path="/forgot-password" layout={UnAuthLayout} component={ForgotPassword} />
          <UnAuthRoute path="/reset-password" layout={UnAuthLayout} component={ResetPassword} />
          <LRoute path="/404" layout={UnAuthLayout} component={Error404} />
          <LRoute path="/500" layout={UnAuthLayout} component={Error500} />

          <LRoute path="/user-pages/blank-page" layout={AdminLayout} component={BlankPage} />

          <LRoute path="/about" layout={DefaultLayout} component={About} />
          <LRoute path="/our-experience" layout={DefaultLayout} component={Experience} />
          <LRoute path="/contact" layout={DefaultLayout} component={Contact} />
          <LRoute path="/board-of-directors" layout={DefaultLayout} component={BoardOfDirectors} />
          <LRoute path="/management-team" layout={DefaultLayout} component={ManagementTeam} />
          <LRoute path="/faqs" layout={DefaultLayout} component={Faqs} />
          <LRoute path="/careers" layout={DefaultLayout} component={Careers} />
          <LRoute path="/research/:page" layout={DefaultLayout} component={Research} />
          <LRoute path="/pricelist" layout={DefaultLayout} component={PriceList} />
          <LRoute path="/ngx-index" layout={DefaultLayout} component={NGXIndex} />
          <LRoute path="/services/stockbroking" layout={DefaultLayout} component={Stockbroking} />
          <LRoute path="/services/issuing-house" layout={DefaultLayout} component={IssuingHouse} />
          <LRoute path="/services/financial-advisory" layout={DefaultLayout} component={FinancialAdvisory} />
          <LRoute path="/services/fund-management" layout={DefaultLayout} component={FundManagement} />
          <LRoute path="/services/portfolio-management" layout={DefaultLayout} component={PortfolioManagement} />
          <LRoute path="/services/other-products" layout={DefaultLayout} component={OtherProducts} />
          <LRoute path="/" layout={DefaultLayout} component={Home} />

          <Redirect to="/404" />
        </Switch>
      </Suspense>
    );
  }

}

export default AppRoutes;