import { createStore } from 'redux';
import reducer from './reducer';
import { loadState, saveState } from "./localStorage";
import throttle from "lodash/throttle";

const persistedState = loadState();
const store = createStore(reducer, persistedState);

store.subscribe(throttle(() => {
    saveState({
        isUserAuthenticated: store.getState().isUserAuthenticated,
        token: store.getState().token,
        user: store.getState().user,
        error: store.getState().error,
        intendedUrl: store.getState().intendedUrl,
    });
}, 1000));

export default store;