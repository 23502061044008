import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import dotenv from 'dotenv';
dotenv.config();

ReactDOM.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
  , document.getElementById('root'));

// serviceWorker.unregister();
serviceWorker.register();