import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';

const select = appState => {
  return {
    user: {
      fullname: appState.isUserAuthenticated ? `${appState.user.first_name} ${appState.user.last_name}` : "",
      initials: appState.isUserAuthenticated ? `${appState.user.first_name.charAt(0)}${appState.user.last_name.charAt(0)}` : "",
      designation: appState.isUserAuthenticated ? appState.user.designation.name : "User",
      permissions: appState.isUserAuthenticated ? appState.user.designation.permissions : false
    }
  }
}

const Wrapper = connect(select);

class Sidebar extends Component {
  state = {}

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/branches', state: 'branchesMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="/">
            <img style={{ width: '150px', height: 'auto', marginTop: '25px' }} src={require("../../dassets/images/logo.jpg")} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="/"><img style={{ height: '30px', width: '30px' }} src={require("../../dassets/images/favicon.jpg")} alt="logo" /></a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                  <div style={{ marginTop: '15px' }} className="d-flex justify-content-between align-items-start">
                    <div className="profile-image">
                      <span style={{ display: "inline-block", height: "40px", width: '40px', borderRadius: '40px', backgroundColor: '#772038', color: "#fff", verticalAlign: "center", paddingTop: "12px", fontSize: "18px", fontWeight: "bold" }}>{this.props.user.initials}</span>
                    </div>
                    <div className="text-left ml-3">
                      <p className="profile-name">{this.props.user.fullname}</p>
                      <small className="designation text-muted text-small">{this.props.user.designation}</small>
                      <span className="status-indicator online"></span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center" href="!#" onClick={evt => evt.preventDefault()}>
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/user" className="dropdown-item preview-item d-flex align-items-center text-small">
                    Manage Accounts
                  </Dropdown.Item>
                  <Dropdown.Item href="/change-password" className="dropdown-item preview-item d-flex align-items-center text-small">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item href="/logout" className="dropdown-item preview-item d-flex align-items-center text-small">
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* {this.props.user.permissions && this.props.user.permissions.memo.create ?
                <Link to="/memos/create" className="btn btn-primary btn-block">New Memo <i className="mdi mdi-plus"></i></Link>
                : ''} */}
            </div>
          </li>
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          {this.props.user.permissions && this.props.user.permissions.department.read ?
            <li className={this.isPathActive('/departments') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/departments">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Departments</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.designation.read ?
            <li className={this.isPathActive('/designations') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/designations">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Designations</span>
              </Link>
            </li>
            : ''}

            {/* {this.props.user.permissions && this.props.user.permissions.designation.read ? */}
              <li className={this.isPathActive('/documents') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/documents">
                  <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                  <span className="menu-title">Documents</span>
                </Link>
              </li>
              {/* : ''} */}

            {/* {this.props.user.permissions && this.props.user.permissions.designation.read ? */}
              <li className={this.isPathActive('/jobs') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/jobs">
                  <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                  <span className="menu-title">Jobs</span>
                </Link>
              </li>
              {/* : ''} */}

            {/* {this.props.user.permissions && this.props.user.permissions.designation.read ? */}
              <li className={this.isPathActive('/contents') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/contents">
                  <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                  <span className="menu-title">Contents</span>
                </Link>
              </li>
              {/* : ''} */}

            {/* {this.props.user.permissions && this.props.user.permissions.designation.read ? */}
              <li className={this.isPathActive('/ngxindex') ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to="/ngxindex">
                  <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                  <span className="menu-title">NGX Market Index</span>
                </Link>
              </li>
              {/* : ''} */}

          {this.props.user.permissions && this.props.user.permissions.iform.read ?
            <li className={this.isPathActive('/individual-forms') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/individual-forms">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Individual Account Forms</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.iform.read ?
            <li className={this.isPathActive('/individual-kyc-forms') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/individual-kyc-forms">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Individual KYC Update Forms</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.jform.read ?
            <li className={this.isPathActive('/joint-forms') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/joint-forms">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Joint Account Forms</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.cform.read ?
            <li className={this.isPathActive('/corporate-forms') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/corporate-forms">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Corporate Account Forms</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.cform.read ?
            <li className={this.isPathActive('/corporate-kyc-forms') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/corporate-kyc-forms">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Corporate KYC Update Forms</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.jform.read ?
            <li className={this.isPathActive('/estate-forms') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/estate-forms">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Estate Account Forms</span>
              </Link>
            </li>
            : ''}

          {this.props.user.permissions && this.props.user.permissions.user.read ?
            <li className={this.isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/users">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Users</span>
              </Link>
            </li>
            : ''}

          {/* {this.props.user.permissions && this.props.user.permissions.report.read ?
            <li className={this.isPathActive('/search') ? 'nav-item active' : 'nav-item'}>
              <Link className="nav-link" to="/search">
                <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                <span className="menu-title">Search</span>
              </Link>
            </li>
            : ''} */}


          {/* <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">Basic UI Elements</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons">Buttons</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns">Dropdowns</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link'} to="/basic-ui/typography">Typography</Link></li>
              </ul>
            </Collapse>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Wrapper(Sidebar));